<template>
  <b-button
    @click="valuation_download"
    :disabled="busy"
    variant='link'
    >
    <font-awesome-icon icon="fa-regular fa-file-chart-column" v-if='!busy' />
    <b-spinner small v-if="busy" />
    <span>Cost of Goods</span>
  </b-button>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'InventoryValuation',
  data() {
    return {
      busy: false,
    }
  },
  methods: {
    
    valuation_download () {
      this.busy = true;
      axios.get( `/inventory/reports/costs_of_goods`, {
          responseType: 'blob',
        })
        .then( response => {
          const FILE = window.URL.createObjectURL (new Blob([ response.data ]) );
          const dl_link = document.createElement( 'a' );
          dl_link.href = FILE;
          dl_link.setAttribute( 'download', response.headers['content-disposition'].split('filename=')[1].split(';')[0] );
          document.body.appendChild( dl_link );
          dl_link.click();
          dl_link.remove();
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: `Failed to export costs of goods`,
          });
        })
        .finally( () => {
          this.busy = false;
        })
    },

  }  
}
</script>