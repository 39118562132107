<template>
  <b-form @submit.prevent="increment_stock" inline>
    <b-form-group class="stock-input">
      <b-form-input
        v-model='delta'
        number
        name="stock"
        type="number"
        placeholder="0"
        size="sm"
        required
        />
    </b-form-group>
    <b-form-group class="valuation-input" v-if='false'>
      <b-input-group prepend="$" size="sm">
        <b-form-input
          v-model='valuation'
          number
          step=".01"
          name="valuation"
          type="number"
          placeholder="0"
          size="sm"
          required
          />
      </b-input-group>
    </b-form-group>
    <b-button
      type="submit"
      variant="primary"
      :disabled='busy || !delta && valuation == this.item.stock_cost'
      >
      <font-awesome-icon icon="fa-solid fa-check" v-if='!busy'/>
      <span class='spinner' v-if="busy">
        <b-spinner small />
      </span>
      <span class='sr-only'>Update</span>
    </b-button>
  </b-form>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'ProductStockIncrementer',
  props: {
    // not actually a wc product, but an object with some product data
    item: {
      type: Object,
      required: true,
    },
  },
  created () {
    this.valuation = this.item.stock_cost;
  },
  data () {
    return {
      delta: 0,
      valuation: 0,
      busy: false,
    }
  },
  methods: {

    increment_stock () {
      this.busy = true;
      axios.put( `/inventory/${ this.item.product_id }/increment/`, { delta: this.delta, valuation: this.valuation })
        .then( response => {
          this.$emit( 'stock-incremented', response.data.product_id, response.data.stock );
          this.delta = 0;
          this.item.stock_cost = this.valuation;
          this.$jjToast({
            type: 'success',
            title: `Updated`,
            content: `Updated ${ this.item.product_name } stock`,
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: `Error`,
            content: `Error Updating ${ this.item.product_name } stock`,
          });
        })
        .finally( () => {
          this.busy = false;
        })
    },

  }
}
</script>


<style lang='scss' scoped>
form {
  margin: 0 -2px;
  & > * {
    margin: 0 2px;
  }
  & /deep/ .form-group {
    flex: 1 1 0;
    > div {
      flex-grow: 1;
    }
    input[name=stock] {
      width: 100%;
    }
  }
  button {
    height: 38px;
    width: 38px;
    position: relative;
    .svg-inline--fa,
    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate( -50%, -50% );
    }
  }
}
@media ( min-width: 576px ) {
  .stock-input {
    max-width: 75px;
  }
  .valuation-input {
    max-width: 100%;
  }
  .btn {
    height: 28px;
  }
}
</style>