<template>
  <div class='inventory-importer'>
    <b-button @click='$refs.modal.show()' variant='link'>
      <font-awesome-icon icon="fa-regular fa-upload" />
      <span>Import</span>
    </b-button>
    <b-modal
      ref='modal'
      class='stock-importer-modal'
      centered
      title='Import Stock'
      size=md
      ok-title='Import'
      @ok='import_file'
      @show='clear_file'
      :busy='busy'
      cancel-variant='medium-dark'
      >
      <b-form-file
        v-model="file"
        :state="file_state"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        :disabled='busy'
        />
    </b-modal>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'InventoryImporter',
  data () {
    return {
      file: null,
      busy: false,
    }
  },
  computed: {

    file_state () {
      return this.file ? true : null;
    }

  },
  methods: {

    clear_file () {
      this.file = null;
    },

    import_file ( e ) {
      e.preventDefault();
      this.busy = true;
      let form_data = new FormData();
      form_data.append( 'file', this.file );
      axios.post( '/inventory/import', form_data )
        .then( response => {
          this.$emit( 'import-complete' );
          this.$refs.modal.hide();
        })
        .finally( () => {
          this.busy = false;
        })
    },

  }
}
</script>