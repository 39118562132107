<template>
  <b-button
    @click="export_inventory"
    :disabled="busy"
    variant='link'
    >
    <font-awesome-icon icon="fa-regular fa-upload" v-if='!busy' />
    <b-spinner small v-if="busy" />
    <span>Export CSV</span>
  </b-button>
</template>

<script>
import axios from '@/axios';
import moment from 'moment';

export default {
  name: 'InventoryExporter',
  data() {
    return {
      busy: false,
    }
  },
  methods: {
    
    export_inventory () {
      this.busy = true;
      axios.get( `/inventory/export`, { responseType: 'blob' })
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', `inventory-export-${ moment().format('DD-MM-YYYY-hh-mm-a') }.csv`);
          document.body.appendChild(docUrl);
          docUrl.click();
        })
        .finally( () => {
          this.busy = false;
        })
    },

  }  
}
</script>