<template>
	<div id='inventory'>
		<b-container fluid class="actions">
			<b-row>
				<b-col cols=12>
					<b-form @submit.prevent>
						<b-row class='filters'>
							<b-col cols=12>
								<b-form-input v-model="filter.term" debounce='250' placeholder="Search" />
							</b-col>
							<b-col cols=12>
								<vue-multiselect
									placeholder='Filter by category'
									v-model='filter.categories'
									:options='categories'
									:multiple='true'
									:close-on-select='false'
									:clear-on-select='false'
									:preserve-search='true'
									@input='refresh'
									:showLabels='false'
									>
									<template slot='selection' slot-scope='{ values, isOpen }'>
										<span v-if='values.length' :style='{ display: isOpen ? `none` : `block` }' class='multiselect__single'>{{ values.length }} categories selected</span>
									</template>
									<span slot='noResult'>No categories found matching search.</span>
								</vue-multiselect>
							</b-col>
						</b-row>
					</b-form>
				</b-col>
				<b-col cols=12>
					<b-row class='action-links'>
						<b-col cols='auto'>
							<InventoryImporter @import-complete='load_archive' />
						</b-col>
						<b-col cols='auto'>
							<InventoryExporter />
						</b-col>
						<b-col cols='auto'>
							<InventoryValuation />
						</b-col>
						<b-col cols='auto'>
							<b-button @click='load_archive' variant='link'>
								<font-awesome-icon icon="fa-regular fa-arrows-rotate" />
								<span>Refresh</span>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-container>

		<div class="table-wrap">
			<b-table
				sticky-header='initial'
				hover
				id="inventory-table"
				ref='table'
				@filtered="on_filtered"
				:filter="filter"
				:filter-included-fields="filter_on"
				:filter-function="filter_items"
				sort-by='product_category'
				:busy="isBusy"
				head-variant="dark"
				:items=items_array
				:fields="fields"
				:per-page="perPage"
				:current-page="currentPage"
				show-empty
				>
				<template #table-busy>
					<jj-loading opaque />
				</template>

				<template #cell(product_name)="data">
					<div>{{ data.item.product_name }}</div>
					<div class='variation-name' v-if='data.item.variation_name'>
						{{ data.item.variation_name }}
					</div>
				</template>
			</b-table>
		</div>
			
		<b-container fluid v-if='totalRows' class='pagination-wrap'>
			<b-row class='justify-content-between align-items-center'>
				<b-col cols="auto">
					<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					aria-controls="inventory-table"
					/>
				</b-col>
				<b-col cols="auto">
					<p>Showing {{ current_page_min }} to {{ current_page_max }} of {{ totalRows }}</p>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import axios from '@/axios';
import { separate_thousands } from '@/functions/formatting';

import InventoryImporter from './importer';
import InventoryExporter from './exporter';
import InventoryValuation from './valuation';
import ProductStockIncrementer from './stock-incrementer';

export default {
  name: 'InventoryList',
  components: {
    InventoryImporter,
    InventoryExporter,
    InventoryValuation,
    ProductStockIncrementer,
  },
  data() {
    return {
      isBusy: true,
      perPage: 50,
      currentPage: 1,
      sortBy: 'product_name',
      sortDesc: false,
      categories: [],
      fields: [
        { 
          key: 'product_name',
          sortable: true,
          formatter: (value, key, item) => {
            // Add the number 1 to keep items with the same product name together
            return `${item.product_name} 1 ${item.variation_name}`;
          },
          filterByFormatted: true,
          sortByFormatted: true
        },
        // { key: 'sku', sortable: true },
        { key: 'product_category', label: 'Category', sortable: true },
        { key: 'stock_available', label: 'On Hand', sortable: true, formatter: 'numeric_column', },
        { key: 'stock_reserved', label: 'Reserved', sortable: true, formatter: 'numeric_column', },
        { key: 'stock_total', label: 'Total', sortable: true, formatter: 'numeric_column', },
        { key: 'status', label: 'Status', sortable: true },
      ],
      items: {},
      filter: {
        term: '',
        categories: [],
      },
      filter_on: [
        'product_name',
        'sku'
      ],
      totalRows: 0,
      dataRefresh: true,
      exporting: false
    }
  },
  computed: {
    items_array () {
      return Object.values( this.items );
    },
    current_page_min () {
      return ( this.currentPage - 1 ) * this.perPage + 1;
    },
    current_page_max () {
      return ( this.currentPage - 1 ) * this.perPage + this.perPage > this.totalRows ? this.totalRows : ( this.currentPage - 1 ) * this.perPage + this.perPage;
    }
  },
  created () {
    this.load_archive();
  },
  methods: {

    refresh () {
      this.$refs.table.refresh();
    },


    filter_items ( row, filter ) {
      if ( !this.filter.categories.length || this.filter.categories.map( cat => cat.toLowerCase() ).includes( row.product_category.toLowerCase() ) ) {
        if ( filter.term == '' ) {
          return true;
        } else if (
          row.product_name.toLowerCase().includes( filter.term.toLowerCase() ) || 
          row.variation_name.toLowerCase().includes( filter.term.toLowerCase() ) || 
          row.sku.toLowerCase().includes( filter.term.toLowerCase() )
          ) {
          return true;
        }
      }

      return false;
    },


    on_filtered ( filtered_items ) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filtered_items.length;
      this.currentPage = 1;
    },


    load_archive () {
      this.isBusy = true;
      axios.get( '/inventory' )
        .then( response => {
          this.items = response.data;
          // filter clears duplicates
          this.categories = this.items_array.map( item => item.product_category ).filter( ( value, index, array ) => array.indexOf( value ) === index ).sort();
          this.on_filtered( this.items_array );
        })
        .catch( () => {

        })
        .finally( () => {
          this.isBusy = false;
        })
    },


    stock_incremented ( product_id, new_stock ) {
      if ( this.items.hasOwnProperty( product_id ) ) {
        Object.entries( new_stock ).forEach( entry => {
          const [ key, value ] = entry;
          this.items[ product_id ][ `stock_${ key }` ] = value;
        });
        this.refresh();
      }
    },


    numeric_column ( value ) {
      return separate_thousands( value );
    }


  }
}
</script>


<style scoped lang='scss'>
#inventory {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
nav {
  border-bottom: 1px solid #f16da4;
}
.tab-height {
  height: calc(100% - 63px);
}
.table-wrap {
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
.b-table-sticky-header {
  flex: 1 1 0;
  margin: 0;
}
.actions {
  padding-top: 1rem;
  padding-bottom: 1rem;
  > .row {
    --gutter-y: 1rem;
  }
  .action-links {
    justify-content: center;
  }
  & /deep/ .btn.btn-link {
    padding: 0;
    color: var( --secondary ) !important;
    &:hover,
    &:focus {
      color: var( --primary ) !important;
    }
    .svg-inline--fa,
    .spinner-border {
      margin-right: 0.5em;
    }
  }
  .action-links {
    justify-content: center;
  }
  .filters {
    --gutter-x: 0.5rem;
    .form-control,
    .multiselect {
      width: 100%;
    }
  }
}
@media ( min-width: 576px ) {
  .actions .filters > * {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media ( min-width: 992px ) {
  .actions {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    > .row {
      justify-content: space-between;
      align-items: center;
      > * {
        flex: 0 0 auto;
        width: auto;
      }
    }
    .filters {
      > * {
        width: auto;
        flex: 0 0 auto;
      }
      .form-control,
      .multiselect {
        width: 250px;
      }
    }
  }
}
.b-table-sticky-header /deep/ #inventory-table {
  table-layout: fixed;
  th,
  td {
    vertical-align: middle;
    padding: 1em 1.5em;

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      // qtys
      width: 115px;
    }

    &:nth-child(6) {
      // qtys and incrementer
      // width: 225px;
      width: 260px;
    }

  }
}
.variation-name {
  font-size: 0.8em;
}
.pagination-wrap {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid var( --bs-border-color );
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.9em;

  p {
    margin: 0;
  }

  ul.pagination {
    justify-content: center;
    margin: 0;
  }
}
</style>